import React, { Component } from 'react';
import { Helmet } from "react-helmet";

import Button from "./Button";
import DisplayCard from "./DisplayCard";
import CheckoutError from "../components/CheckoutError";
import { AppContext } from "../utils/context";

class CheckoutEnvelope extends Component {
    constructor(props) {
        super(props);
        const { context } = props;
        this.state = {
          pricePointCount: context?.event?.prices?.length ?? 0
        }
    }
    render() {
        const {
            handleEnvelope,
            validateCartFinal,
            envelopes,
            goToPrevious,
            envelope_error,
            envelope,
            valid,
            scripts_pick_envelope,
            envelope_number_color,
            envelope_background_image,
            envelopeBackgroundImageHeight,
            context,
            errorReturnHome
        } = this.props;
        const { localLanguage } = this.context;
        const { pricePointCount } = this.state;

        const eventEndedAlert = {
            code: 3,
            message: localLanguage.general_event_end_return_home,
            action: "default"
        }
        const priceChangeAlert = {
            code: 4,
            message: localLanguage.general_event_pricing_change,
            action: "default"
        }

        if (!context.event.is_active || context.event.web_sales_paused) { // alert user that event ended
            return (<CheckoutError
                error={eventEndedAlert}
                errorReturnHome={errorReturnHome}
            />);
        }
        if (pricePointCount !== context.event.prices.length) { // alert user of dynamic pricing changes
            return (<CheckoutError
                error={priceChangeAlert}
                errorReturnHome={errorReturnHome}
            />);
        }

        return (
            <div className="flex flex-wrap justify-center mb-6 checkoutEnvelope">
                <Helmet>
                    {scripts_pick_envelope && <script>{scripts_pick_envelope}</script>}
                </Helmet>
                {envelopes.map((value, key) => (
                    <DisplayCard
                        envelopeNumberColor={envelope_number_color}
                        envelopeBackgroundImage={envelope_background_image}
                        envelopeBackgroundImageHeight={envelopeBackgroundImageHeight}
                        key={key}
                        cardInfo={value}
                        clickHandler={handleEnvelope}
                        envelope={envelope} />
                ))}
                {envelope_error && (
                    <div className="w-full flex justify-center mt-6">
                        <div className="bg-white border border-danger text-danger px-4 py-3 rounded relative" role="alert">
                            {
                                envelope_error &&
                                <i className="block md:inline font-bold">{valid.envelope}</i>
                            }
                        </div>
                    </div>
                )}
                <div className="w-full mt-10 flex flex-wrap justify-between justify-center">
                    <Button onClick={goToPrevious} color="secondary" textColor="primary" content={localLanguage.general_back} />
                    <Button color="accent" textColor="primary" onClick={validateCartFinal} content={localLanguage.general_continue} continueButton="true"/>
                </div>
            </div>
        );
    }
}
CheckoutEnvelope.contextType = AppContext;

export default CheckoutEnvelope;

import React, { Component } from "react";
import { Helmet } from "react-helmet";
import ReCAPTCHA from "react-google-recaptcha";
import parse from 'html-react-parser';

import Button from "./Button";
import CheckoutError from "../components/CheckoutError";
import { AppContext } from "../utils/context";

const recaptchaRef = React.createRef();

class CheckoutVerify extends Component {
  constructor(props) {
    super(props);
    const { context, data } = props;
    this.state = {
      disabled: props.recaptchaType === 'v2', // if type is v2, submit is disabled until recaptcha complete
      recaptchaRef: recaptchaRef,
      addonCount: Object.keys(context?.event?.addons)?.length ?? 0,
      addonEvents: context?.event?.addons ?? null,
      currentEvent: context?.event
    };
  }
  onChange = (value) => {
    if (value) {
      this.setState({ disabled: false });
    } else {
      this.setState({ disabled: true });
    }
  }
  handleSubmit = () => {
    const { recaptchaType } = this.props;
    if (recaptchaType === 'invisible') {
      recaptchaRef.current.executeAsync().then(value => {
        // Continue on to validation
        this.props.validateConfirm(recaptchaRef.current.getValue());
      });
    } else {
      // Continue on to validation
      this.props.validateConfirm(recaptchaRef.current.getValue());
    }
  }
  render() {
    const {
      data,
      validateConfirm,
      payment,
      context,
      goToPrevious,
      personal_info_error,
      freeTicket,
      recaptchaKey,
      recaptchaType,
      orderRecaptchaRequired,
      isProcessLoading,
      giftingOption,
      errorReturnHome
    } = this.props;
    const { localLanguage } = this.context;
    const { disabled, addonCount, addonEvents, currentEvent } = this.state;
    const subscribeOptions= data.subscribe ?? [];
    const agreementOptions = data.agreement ?? [];
    const eventEndedAlert = {
        code: 3,
        message: localLanguage.general_event_end_return_home,
        action: "default"
    }

    const priceChangeAlert = {
        code: 4,
        message: localLanguage.general_event_pricing_change,
        action: "default"
    }

    if (!context.event.is_active || context.event.web_sales_paused) { // alert user that event ended
        return (<CheckoutError
            error={eventEndedAlert}
            errorReturnHome={errorReturnHome}
        />);
    }

    let eventAddonsCount = Object.keys(context?.event?.addons)?.length;
    if (context.config.option_allow_addons && addonCount !== eventAddonsCount) { // alert user of addon event changes
        return (<CheckoutError
            error={priceChangeAlert}
            errorReturnHome={errorReturnHome}
        />);
    }

    let prevEventAddons = context?.event?.addons ?? null;
    if (context.config.option_allow_addons && prevEventAddons && addonCount) { // alert user of addon dynamic pricing changes
        const addonPricingChange = addonEvents.every((addon, key) => {
            let prevAddonPrices = context?.event?.addons[key]?.prices;
            if (prevAddonPrices) return (JSON.stringify(prevAddonPrices) !== JSON.stringify(addon.prices));
        });
        if (addonPricingChange)
            return (<CheckoutError
                error={priceChangeAlert}
                errorReturnHome={errorReturnHome}
            />);
    }

    let prevEvent = context?.event;
    if (prevEvent && currentEvent && JSON.stringify(prevEvent.prices) !== JSON.stringify(currentEvent.prices)) { // alert user of dynamic pricing changes
        return (<CheckoutError
            error={priceChangeAlert}
            errorReturnHome={errorReturnHome}
        />);
    }

    if (isProcessLoading) {
      return (
        <div className="flex flex-wrap items-center h-64">
          <div className="w-2/3 m-auto">
            <div className="flex text-2xl text-primary">
              <div className="bump-loader" style={{ position: 'relative', marginRight: '20px', marginTop: '-15px', left: '0%' }}>
                <div></div>
                <div></div>
                <div></div>
              </div>
              {localLanguage.general_processing_your_order}
            </div>
          </div>
        </div>
      )
    }
    return (
      <form className="w-full md:w-2/3 p-5">
        <Helmet>
          {context.settings.scripts_confirm_order && <script>{context.settings.scripts_confirm_order}</script>}
        </Helmet>
        <h2 className="mb-6">{localLanguage.general_billing_details}</h2>
        <p className="leading-normal mb-4">
          {data.first_name} {data.last_name}
        </p>
        <p className="leading-normal mb-4">
          {data.address}
          <br />
          {data.suite !== "" ? (
            <span className="block">{localLanguage.general_suite_unit_apt} {data.suite}</span>
          ) : (
            ""
          )}
          {data.city}, {data.state} {data.zip_code}
        </p>

        <p className="leading-normal">{localLanguage.general_e_email}: {data.email}</p>
        <p className="leading-normal">{localLanguage.general_p_phone}: {data.phone}</p>
        {data.phone_home && <p className="leading-normal">{localLanguage.general_t_home_phone}: {data.phone_home}</p>}
        {data.phone_work && <p className="leading-normal">{localLanguage.general_w_work_phone}: {data.phone_work}</p>}

        {+giftingOption > 0 && data.recipientList.length > 0 && <div className="leading-normal mt-2 ">
          <span className="recipientHeaderVerifyPage">{localLanguage.general_recipient_details}:</span>
          {data.recipientList.map((key, value) => data.recipientList[value]['recipientListFirstName'] !== "" &&
            <p key={value} className="leading-normal mt-1 mx-4 mt-3 mb-3 recipientSectionVerifyPage">
              <span className="recipientInfoVerifyPage">{+value + 1}. {data.recipientList[value]['recipientListFirstName']} {data.recipientList[value]['recipientListLastName']} | {data.recipientList[value]['recipientListEmail']}</span>
            </p>
          )}
        </div>}

        <p className="leading-normal mt-2 mb-2">
          <span className="font-bold">
            {localLanguage.general_receive_comm_via_url.replace("{appUrl}", context.event.nonprofit.name)}
          </span>{" "}
          <br />
          {subscribeOptions.map((v, k) => (
            <span key={`subscribe${k}`} className="flex items-center" hidden={v.text === ""}>
              {v.text !== "" && (
                <span>
                  {v.checked === true ? (
                    <svg
                      className="fill-current w-4 h-4 mr-2 text-success"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM6.7 9.29L9 11.6l4.3-4.3 1.4 1.42L9 14.4l-3.7-3.7 1.4-1.42z" />
                    </svg>
                  ) : (
                      <svg
                        className="fill-current w-4 h-4 mr-2 text-danger"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm1.41-1.41A8 8 0 1 0 15.66 4.34 8 8 0 0 0 4.34 15.66zm9.9-8.49L11.41 10l2.83 2.83-1.41 1.41L10 11.41l-2.83 2.83-1.41-1.41L8.59 10 5.76 7.17l1.41-1.41L10 8.59l2.83-2.83 1.41 1.41z" />
                      </svg>
                    )}

                  {v.text && parse(v.text)}
                </span>
              )}
            </span>
          ))}
        </p>
        <p className="leading-normal">
          {agreementOptions.map((v, k) => (
            <span key={`agreement${k}`} className="flex items-center" hidden={v.text === ""}>
              {v.text !== "" && (
                <span>
                  {v.checked === true ? (
                    <svg
                      className="fill-current w-4 h-4 mr-2 text-success"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM6.7 9.29L9 11.6l4.3-4.3 1.4 1.42L9 14.4l-3.7-3.7 1.4-1.42z" />
                    </svg>
                  ) : (
                      <svg
                        className="fill-current w-4 h-4 mr-2 text-danger"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm1.41-1.41A8 8 0 1 0 15.66 4.34 8 8 0 0 0 4.34 15.66zm9.9-8.49L11.41 10l2.83 2.83-1.41 1.41L10 11.41l-2.83 2.83-1.41-1.41L8.59 10 5.76 7.17l1.41-1.41L10 8.59l2.83-2.83 1.41 1.41z" />
                      </svg>
                    )}

                  {parse(v.text)}
                </span>
              )}

            </span>
          ))}
        </p>

        {personal_info_error && (
          <div className="w-full flex justify-center mt-6">
            <div className="bg-white border border-danger text-danger px-4 py-3 rounded relative" role="alert">
              {
                personal_info_error &&
                <i className="block md:inline font-bold">{localLanguage.general_complete_billing_details}</i>
              }
            </div>
          </div>
        )}
        {(freeTicket || orderRecaptchaRequired) &&
          (<div className="mb-2 mt-2">
            <ReCAPTCHA
              ref={this.state.recaptchaRef}
              sitekey={recaptchaKey}
              onChange={recaptchaType === 'v2' ? this.onChange : null}
              size={recaptchaType === 'v2' ? 'small' : 'invisible'}
            />
          </div>)
        }
        <div className="w-full flex flex-wrap mb-6 justify-between">
          <Button onClick={goToPrevious} color="secondary" textColor="primary" content={localLanguage.general_back} />
          {!freeTicket && !orderRecaptchaRequired ?
            (<Button color="accent" textColor="primary" onClick={() => validateConfirm()} content={localLanguage.general_continue} continueButton="true" />)
            :
            !disabled && (<Button color="accent" textColor="primary" onClick={() => this.handleSubmit()} content={localLanguage.general_continue} continueButton="true" />)
          }
        </div>
      </form>
    );
  }
}
CheckoutVerify.contextType = AppContext;

export default CheckoutVerify;
